import styled from '@emotion/styled'
import React, { memo } from 'react'

export interface Props {
  className?: string
}

export const Lines = memo(function Lines({ className = '' }: Props) {
  return (
    <Container className={className}>
      <LineLeft />
      <LineCenter />
      <LineRight />
    </Container>
  )
})

const Container = styled.div`
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`
const Line = styled.div`
  top: 0;
  position: absolute;
  height: 100%;
  width: 1px;
  background: ${({ theme }) => theme.colors.variants.neutralLight2};
`
const LineLeft = styled(Line)`
  left: 25%;
`
const LineCenter = styled(Line)`
  left: 50%;
`
const LineRight = styled(Line)`
  left: 75%;
`
